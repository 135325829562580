import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import store from '../../../store';
import Cleave from 'cleave.js/react';
import { navigate } from 'gatsby';
import 'antd/dist/antd.css';
import './index.css';
import {
  Form,
  Input,
  InputNumber,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Radio,
} from 'antd';
//import Button from 'common/src/components/Button';
import ContactFromWrapper, { SectionMainWrapper } from '../contact.style';
//import ContentWrapper from '../contact.style';

import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import {
  createUsers,
  getUser,
  showLoader,
  hideLoader,
  loginFail,
} from '../../../actions';

const { Option } = Select;

const GetEmailRefi = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  description,
  description2,
  description3,
  currrentUser,
  createUsers,
  loading,
  loggedIn,
  loginFail,
}) => {
  const [form] = Form.useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(null);

  const sendToSignIn = (e) => {
    navigate('/login/');
  };

  const onFinish = async (values) => {
    console.log('onFinish values ', values);
    //create object from local storage (becuase currentUser doesnt exist yet)
    var updateObject = {
      subjectProperty: {
        address: {
          addressLineText: JSON.parse(localStorage.getItem('propertyAddress')),
          addressUnitIdentifier: JSON.parse(
            localStorage.getItem('addressUnitIdentifier')
          ),
          cityName: JSON.parse(localStorage.getItem('city')),
          stateCode: JSON.parse(localStorage.getItem('state')),
          postalCode: JSON.parse(localStorage.getItem('postalCode')),
        },
        propertyDetail: {
          propertyUsageType: JSON.parse(
            localStorage.getItem('propertyUsageType')
          ),
          propertyType: JSON.parse(localStorage.getItem('propertyType')),
          PropertyEstimatedValueAmount: parseFloat(
            JSON.parse(localStorage.getItem('statedPropertyValue')).replace(
              /,/g,
              ''
            )
          ),
        },
      },
      assets: {
        realEstate: {
          address: {
            addressLineText: JSON.parse(
              localStorage.getItem('propertyAddress')
            ),
            addressUnitIdentifier: JSON.parse(
              localStorage.getItem('addressUnitIdentifier')
            ),
            cityName: JSON.parse(localStorage.getItem('city')),
            stateCode: JSON.parse(localStorage.getItem('state')),
            postalCode: JSON.parse(localStorage.getItem('postalCode')),
          },
          propertyDetail: {
            propertyUsageType: JSON.parse(
              localStorage.getItem('propertyUsageType')
            ),
            propertyType: JSON.parse(localStorage.getItem('propertyType')),
            PropertyEstimatedValueAmount: parseFloat(
              JSON.parse(localStorage.getItem('statedPropertyValue')).replace(
                /,/g,
                ''
              )
            ),
          },
        },
      },
      loan: {
        refinance: {
          RefinancePrimaryPurposeType: JSON.parse(
            localStorage.getItem('financingPurpose')
          ),
        },
        termsOfLoan: {
          loanPurposeType: 'Refinance',
          purchasePrice: parseFloat(
            JSON.parse(localStorage.getItem('statedPropertyValue')).replace(
              /,/g,
              ''
            )
          ),
        },
      },
      miscellaneous: {
        applicationStep: '/buy-pages/tellUsAboutYourself/',
      },
      email: values.email,
    };
    console.log('onFinish values ', updateObject);
    //send object
    await createUsers(updateObject);
    const state = store.getState();
    if (state.root.loggedIn == true) {
      setEmailAlreadyExists(false);
      navigate('/creditSoftPullNotice/');
    } else {
      setEmailAlreadyExists(true);
    }
  };

  useEffect(() => {
    const now = new Date();

    //   read date from local storage
    const saved_item_str = localStorage.getItem('data');
    console.log('Saved Item', saved_item_str);
    if (saved_item_str) {
      const saved_item = JSON.parse(saved_item_str);
    }

    //   const item = {
    // 	address: "test address",
    // 	expirty: now.getTime() + 3600
    //   }
    /* if (loggedIn) {
		console.log("in loggedin = true",  loggedIn)
		setEmailAlreadyExists(false);
    } if(!loggedIn) {
		console.log("in loggedin = false", loggedIn)
      	setEmailAlreadyExists(true);
    } */
    //   localStorage.setItem("data", JSON.stringify(item));
    //   console.log("Just save");
  }, [loggedIn]); // <-- empty dependency array

  return (
    <SectionMainWrapper>
      <Box {...sectionWrapper}>
        <Container className="containerClass">
          <Box {...secTitleWrapper}>
            <Box {...row}>
              <Box {...contactForm}>
                <ContactFromWrapper>
                  <Form
                    //{...formItemLayout}
                    form={form}
                    layout="vertical"
                    name="propertyAddress"
                    onFinish={onFinish}
                    scrollToFirstError
                    style={{ width: '80%' }}
                  >
                    <Heading fontWeight="bold" {...title} />
                    <Text fontWeight="bold" {...description} />

                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: 'email',
                          message: 'Please enter a valid E-mail',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    {emailAlreadyExists && (
                      <Text fontWeight="bold" {...description2} />
                    )}

                    <Form.Item>
                      <Button
                        //type="submit" or "primary" not sure
                        htmlType="submit"
                        color="white"
                        /* onClick={onFinish} */
                        title="Continue"
                        {...button}
                      >
                        Continue
                      </Button>
                    </Form.Item>
                    <span>
                      <label
                        style={{
                          fontSize: '13px',
                          cursor: 'pointer',
                          color: 'blue',
                        }}
                      >
                        <input
                          type="radio"
                          value="researching"
                          name="radio1"
                          id="radio1"
                          style={{ visibility: 'hidden' }}
                          onChange={sendToSignIn}
                        />
                        <span>Already have an account? Sign in</span>
                      </label>
                    </span>
                    {/* <Text fontWeight="bold" {...description3} /> */}
                  </Form>
                </ContactFromWrapper>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </SectionMainWrapper>
  );
};

GetEmailRefi.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

GetEmailRefi.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    //borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`,
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'Your rates are almost ready',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },

  description: {
    content:
      'Enter your email address to save your progress, then continue to see your rates',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  description2: {
    content:
      'You already have an account with this email. To continue, sign in.      ',
    fontSize: '16px',
    fontWeight: '400',
    color: 'red',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  description3: {
    content: 'Already have an account? ' + 'sign in',
    fontSize: '16px',
    fontWeight: '400',
    color: '#131176',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
};

const mapStateToProps = ({ root: { currrentUser, loading, loggedIn } }) => ({
  currrentUser,
  loading,
  loggedIn,
  loginFail,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUsers: (formVals) => dispatch(createUsers(formVals)),
    getUser: (userId) => dispatch(getUser(userId)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

const GetEmailRefiRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetEmailRefi);

export default GetEmailRefiRedux;
